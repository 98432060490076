import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  checkIn () {
    let checkInModal = document.getElementById('checkInModal')  
    let myModal = new bootstrap.Modal(checkInModal);
  
    myModal.show();
    let userId = $('#hubspotForm').attr('data-user-id')
    let url = $('#hubspotForm').attr('data-url')
    let csrfToken = $('meta[name="csrf-token"]').attr('content');
  
    hbspt.forms.create({
      region: "na1",
      portalId: "20835481",
      formId: "a43c8cbd-ffd9-4281-a5fa-90727aaf5156",
      target: "#hubspotForm",
      onFormSubmitted: function() {
        $.ajax({
          type: 'put',
          url: url,
          data: { user: { id: userId, checkin_completed: true, checking_in: true } },
          headers: {
            'X-CSRF-Token': csrfToken
          }
        })
        $('#modal-dismiss-buttons').remove();
        $('#modal-close-button').removeClass('d-none');
      }
    });
  }

  openPreCheckinForm () {
    let preCheckInModal = document.getElementById('preCheckInModal')
    let myModal = new bootstrap.Modal(preCheckInModal);
    myModal.show();

    let url = $('#preCheckInForm').attr('data-url')
    let csrfToken = $('meta[name="csrf-token"]').attr('content');
    let formId = $('#preCheckInForm').attr('data-form-id')
    let courseUserId = $('#preCheckInForm').attr('data-course-user-id')

    hbspt.forms.create({
      region: "na1",
      portalId: "20835481",
      formId: formId,
      target: "#preCheckInForm",
      onFormSubmitted: function() {
        $.ajax({
          type: 'put',
          url: url,
          data: { course_user: { id: courseUserId, pre_checkin_completed_at: new Date() } },
          headers: {
            'X-CSRF-Token': csrfToken
          }
        })
        $('#pre-checkin-button').remove();
      }
    });
  }

  openPostCheckinForm () {
    let postCheckInModal = document.getElementById('postCheckInModal')
  
    let myModal = new bootstrap.Modal(postCheckInModal);
    myModal.show();

    let url = $('#postCheckInForm').attr('data-url')
    let csrfToken = $('meta[name="csrf-token"]').attr('content');
    let formId = $('#postCheckInForm').attr('data-form-id')
    let courseUserId = $('#postCheckInForm').attr('data-course-user-id')

    hbspt.forms.create({
      region: "na1",
      portalId: "20835481",
      formId: formId,
      target: "#postCheckInForm",
      onFormSubmitted: function() {
        $.ajax({
          type: 'put',
          url: url,
          data: { course_user: { id: courseUserId, post_checkin_completed: new Date() } },
          headers: {
            'X-CSRF-Token': csrfToken
          }
        })
        $('#post-checkin-button').remove();
      }
    });
  }

  openSupportForm () {
    let supportFormModal = document.getElementById('supportFormModal')
  
    let myModal = new bootstrap.Modal(supportFormModal);
    myModal.show();

    hbspt.forms.create({
      region: "na1",
      portalId: "20835481",
      formId: "c29cf250-73ec-4f06-b003-94ae7fbfd9ef",
      target: "#supportForm"
    });
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'url' ]

  click(e){
    if (this.hasUrlTarget) {
      Turbo.visit(this.url)
    }
  }

  get url() {
    return this.urlTarget.href
  }
}
import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ["switch"]
  static values = {
    url: String,
    permission: String
  }

  initialize() { }

  connect() { }

  async toggle(event) {
    // check if the permission was granted or removed based on the status of the checkbox
    var action = "granted"
    if (this.switchTarget.checked == false) {
      action = "revoked"
    }

    // double check the user knows what they are doing!
    let result = confirm(`Are you sure you want "${this.permissionValue} ${action}"?`)
    if (result === false) {
      this.switchTarget.checked = !this.switchTarget.checked;
      event.preventDefault();
      event.stopImmediatePropagation();
      return false;
    }

    var data = { 'user': { 'permission': this.permissionValue } }

    // make the request with request.js
    const request = new FetchRequest('patch', this.urlValue, { responseKind: 'json', body: JSON.stringify(data) })
    const response = await request.perform()
    if (response.ok) {
      const json = await response.json
      // Do whatever do you want with the response body
      // You also are able to call `response.html` or `response.json`, be aware that if you call `response.json` and the response contentType isn't `application/json` there will be raised an error.
      window.notifier.success(`Permission ${action}:  ${this.permissionValue}`, {})
    } else {
      this.switchTarget.checked = false;
    }

  }
}

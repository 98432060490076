// https://discuss.hotwired.dev/t/empty-state-with-turbo-stream-in-rails/2068/5
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["emptyState", "item"];

  // mutation observer on the element the controller is placed on
  connect() {
    this.observer = new MutationObserver(this.update.bind(this));
    this.observer.observe(this.element, {
      childList: true,
      attributes: false,
      subtree: true
    });
    this.update();
  }

  disconnect() {
    this.observer.disconnect();
  }

  // any time the dom updates check the count of the elements with a target of "item"
  update() {
    this.emptyStateTarget.classList.toggle("d-none", this.itemTargets.length !== 0);
  }
  
}
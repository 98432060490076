import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    window.vidyardEmbed
      ? this.registerProgressEvents(window.vidyardEmbed)
      : (window.onVidyardAPI = (vidyardEmbed) => this.registerProgressEvents(vidyardEmbed));
  }

  registerProgressEvents(vidyardEmbed) {
    vidyardEmbed.api.addReadyListener((data, player) => {
      vidyardEmbed.api.progressEvents((result) => {
        this.completeLesson(result.player);
        console.log('lesson complete');
      }, [70])
    })
  }

  completeLesson(player) {
    const url = player.element.querySelector('img').dataset.lessonUrl;
    const lessonId = player.element.querySelector('img').dataset.lessonId;
    const blankCheckTargets = document.querySelectorAll(`.lesson-${lessonId}-checkmark`)
    const completedCheckmark = document.querySelector('#completed-checkmark').content
    let csrfToken = $('meta[name="csrf-token"]').attr('content');

    $.ajax({
      type: 'patch',
      url: url,
      headers: {
        'X-CSRF-Token': csrfToken
      },
      success: function () {
        blankCheckTargets.forEach((target) => (target.replaceWith(completedCheckmark)));
      }
    })
  }
}
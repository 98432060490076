import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = [ 'email' ]

    copy() {
        // Copy email address method
        var parent = this.emailTarget.parentElement;
         
        // Method for copying email address from Table
        if (parent.nodeName == "TD") {
            var index = parent.parentElement.rowIndex - 1;
            var email = this.emailTarget.value;

            navigator.clipboard.writeText(email).then(function() {
                document.getElementsByClassName('clipboard')[index].setAttribute('data-bs-original-title', 'Copied!');
                setTimeout(function(){ 
                    document.getElementsByClassName('clipboard')[index].setAttribute('data-bs-original-title', 'Copy Email to Clipboard')
                 }, 2500);
            })
        }

        // ToDo if needed: copying email address elsewhere
    }
}
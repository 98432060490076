import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = []

  static values = {
    id: Number,
    url: String,
    readUrl: String
  }

  initialize() { }

  connect() { }

  async readAndNav(event) {
    // make the request with request.js
    const request = new FetchRequest('patch', this.readUrlValue, { })
    const response = await request.perform()
    if (response.ok) {
      const body = await response.text
      // Do whatever do you want with the response body
      // You also are able to call `response.html` or `response.json`, be aware that if you call `response.json` and the response contentType isn't `application/json` there will be raised an error.
      Turbo.visit(this.urlValue)
    } else {
      console.error(`[ERROR] ${response.statusCode}`)
    }
  }

  async markAsRead(event) {
    event.preventDefault()
    event.stopPropagation()

    this.element.classList.add('d-none')

    // make the request with request.js
    const request = new FetchRequest('patch', this.readUrlValue, { })
    const response = await request.perform()
    if (response.ok) {
      const body = await response.text
      // Do whatever do you want with the response body
      // You also are able to call `response.html` or `response.json`, be aware that if you call `response.json` and the response contentType isn't `application/json` there will be raised an error.
      this.element.opacity = 0
    } else {
      console.error(`[ERROR] ${response.statusCode}`)
      this.element.classList.add('d-block text-danger')
    }
  }

}

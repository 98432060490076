import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'button' ]

  highlightButton(event) {
    let skip = false;
    if (event.currentTarget.classList.contains("active")) {
      skip = true;
    }
    document.querySelectorAll(".light-green-button.active").forEach((element) => element.classList.remove("active"));
    if (!skip) {event.currentTarget.classList.add("active")}
  }
}

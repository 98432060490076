import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'icon' ]

  rotateIcon(event) {
    if (event.currentTarget.classList.contains("collapsed"))
      {
        this.iconTarget.classList.remove("expanded-chevron")
      } else {
        this.iconTarget.classList.add("expanded-chevron")
      }
      
  }
}

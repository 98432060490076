console.log('Vite ⚡️ Rails')

import { FetchRequest } from '@rails/request.js'

import "@hotwired/turbo-rails"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

const channels = import.meta.globEager('./**/*_channel.js')
import Sortable from 'sortablejs'

// action text
import "trix"
import "@rails/actiontext"

// jquery
import jQuery from "jquery"
window.jQuery = jQuery
window.$ = jQuery

// bootstrap 5
import * as bootstrap from "bootstrap"

// fancy select
import Choices from 'choices.js'

// charts
import ApexCharts from 'apexcharts'
window.ApexCharts = ApexCharts

// notifications
import AWN from "awesome-notifications"
let globalOptions =  {}
window.notifier = new AWN(globalOptions)
import 'awesome-notifications/dist/index.var.js'

// identicons (for the user avatar)
import * as jdenticon from "jdenticon"
window.jdenticon = jdenticon;

import "masonry-layout"

import "../controllers"

// theme for Dashkit
import '../theme/theme'


document.addEventListener("turbo:load", function(event) { 
// initialize bootstrap toasts
  var toastElList = [].slice.call(document.querySelectorAll('.toast'))
  var toastList = toastElList.map(function (toastEl) {
      var option = {}
      return new bootstrap.Toast(toastEl, option).show()
  })

  // initialize bootstrap tooltips
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  // popovers
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'), {
    container: 'body',
    trigger: 'focus'
  })
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })
})

document.addEventListener("turbo:load", function(event) { 
  // jdenticon
  window.jdenticon.update("[data-jdenticon-value],[data-jdenticon-hash]")

  // Masonry
  if (!document.querySelector(".grid")) return
  var msnry = new Masonry( '.grid', {
    // options
  });
})